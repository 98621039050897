import { state } from ":mods";
import { Portal } from "solid-js/web";
import { MetaViewProps, MetaContextActions } from "../model";
import { MetaContext } from "../store";
export function Meta({ children, ...props }: MetaViewProps) {
  const $meta = state.create(props);
  const $title = $meta.derive((f) => f.title[0].toUpperCase() + f.title.substring(1).toLowerCase());

  MetaContext.defaultValue = {
    setTitle(title) {
      $meta.set({ title: props.title + " | " + title });
    },
  };
  return (
    <MetaContext.Provider value={MetaContext.defaultValue}>
      <Portal mount={document.head}>
        <title>{$title()}</title>
      </Portal>
      {children}
    </MetaContext.Provider>
  );
}
